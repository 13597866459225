<template>
<div id="checkup">
  <el-row type="flex" justify="center">
    <el-col :md="20" :lg="18" :xl="15">
      <PageHeader/>

      <DepartmentAside aside-color="#eb97ae" title="健康診断">
        <div class="img">
          <el-image :src="require(`@/assets/departments/checkup.png`)"/>
        </div>
        <div class="detail1">
          <div class="content1">
            <div class="title1">入社時健診（雇用時健診）</div>
            <div class="text">当院では血液検査、尿検査、心電図、レントゲン、聴力検査（8月21日予定）、呼吸機能検査の機器が揃いました。8月23日から雇用時（入職時）健診を行います。当院の雇用時健診の特徴はスピードです。一般項目であれば、最短で営業日であれば翌日お渡しが可能です。</div>
          </div>
          <div class="content1">
            <div class="title1">健診項目</div>
            <div class="text">1. 既往歴及び業務歴の調査</div>
            <div class="text">2. 自覚症状及び他覚症状の有無の検査</div>
            <div class="text">3. 身長、体重、腹囲、視力及び聴力の検査</div>
            <div class="text">4. 胸部エックス線検査</div>
            <div class="text">5. 血圧の測定</div>
            <div class="text">6. 貧血検査 （赤血球数、血色素量）</div>
            <div class="text">7. 肝機能検査（GOT、GPT、γ－GTP）</div>
            <div class="text">8. 血中脂質検査（LDL コレステロール、HDL コレステロール、血清トリグリセライド）</div>
            <div class="text">9. 血糖検査</div>
            <div class="text">10. 尿検査（尿中の糖及び蛋白の有無の検査）</div>
            <div class="text">11. 心電図検査（安静時心電図検査）</div>
          </div>
          <div class="content1">
            <div class="title1">健診費用</div>
            <div class="text">雇用時健診費用：10000円（税込み）</div>
            <div class="text">レントゲンもしくは心電図なしの場合は7500円（税込み）</div>
          </div>
          <div class="other">※その他、感染症の抗体や腎機能など、会社や学校によって追加で必要な項目も別途に追加できます（別料金がかかります）。詳しくは当院にお問い合わせ下さい。</div>
        </div>
      </DepartmentAside>

    </el-col>
  </el-row>

  <PageFooter/>
</div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import DepartmentAside from "@/components/DepartmentAside";
import PageFooter from "@/components/PageFooter";
export default {
  name: "Checkup",
  components: {PageFooter, DepartmentAside, PageHeader}
}
</script>

<style scoped>
#checkup {
  border-top: 10px solid #1796c4;
}

.detail1 {
  margin: 30px 10px;
}

.detail1 .content1 div {
  font-family: "ヒラギノ丸ゴProN W4", "Hiragino Maru Gothic ProN", "HG丸ｺﾞｼｯｸM-PRO", HGMaruGothicMPRO, "メイリオ", Meiryo, Osaka, "MS Pゴシック", "MS PGothic", sans-serif;
  font-size: 18px;
}
.content1 {
  line-height: 1.7;
  margin-bottom: 2rem;
}
.content1 .title1 {
  color: #C34668;
  font-weight: bold;
}
.content1 .text {
  color: #333;
}
.other {
  color: #333;
  font-size: 14px;
  line-height: 1.5;
}
</style>
